import React from "react";
import "./FAQs.css";
import Accordion from "react-bootstrap/accordion";

function FAQs() {
  return (
    <div className="faqs">
      <h3 className="text-center">Frequently Asked Question</h3>
      <Accordion className="faqs__item">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            What material are PVC Doors made of ?
          </Accordion.Header>
          <Accordion.Body>
            PVC doors, as the name suggests, are made up of Poly Vinyl Chloride
            that has insulating properties. It is because of PVC that the door
            has energy efficiency better than the other materials. The benefits
            of Poly Vinyl Chloride are not unknown to many. The material finds
            its application in electric wiring, waterproof boards, workstations,
            and a lot more. We at unipan use high quality raw materials for
            manufacturing our products.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Are PVC Doors reliable ?</Accordion.Header>
          <Accordion.Body>
            Yes, PVC Doors are durable. PVC has high tolerance to shocks (such
            as banging) and abrasions (from rough use). Raw PVC is mixed with
            impact modifiers, heat stabilisers, thermal modifiers and fillers
            that make the panel suitable for home applications. Properly treated
            and installed PVC doors are resilient and can have a long life.
            Unlike wood and metal, a PVC sheet is coated with a thin plastic
            film, which makes it chemically resistant to acids, bases and salts.
            Hence, it is free of the risk of rotting, rusting and fading in
            high-moisture areas like bathrooms.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            How to check the quality of PVC Doors?
          </Accordion.Header>
          <Accordion.Body>
            1)The PVC profiles should be resistant to cracking and flaking.{" "}
            <br />
            2)Additionally, check that the material is flame-retardant and
            resistant to sustained exposure to fire. <br />
            3)Always check with the gauge of the profiles which the companies
            are claiming and one can also check by pressing the profiles. <br />{" "}
            4)The PVC doors should be resistant to UV (ultraviolet) radiation so
            that the windows do not discolour and get a yellowish tinge due to
            prolonged exposure to sunlight.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            What are the differences between PVC and WPC Doors?
          </Accordion.Header>
          <Accordion.Body>
            Wood Plastic Composite or WPC is a hybrid composite material that is
            mainly made from a distinct mixture of natural wood and plastic
            fibers.
            <br />
            Polymerization of Vinyl Chloride or PVC is a type of durable and
            long-lasting material used globally in the construction, packaging,
            transport, healthcare, automobile, and electrical sector. It is very
            versatile and economical in nature. The products that are mainly
            created with PVC range from blood bags and tubing, piping, siding,
            wire and cable insulation, windshield system components, and various
            others. <br />
            The main reason behind its global popularity and acceptance is its
            ability to resist water and fire. Nowadays, colored PVC boards are
            used as an alternative for metal, clay, wood, concrete, and glass in
            many products.
            <br />
            There are several advantages of WPC and PVC which are very distinct
            from each other. However, it is very hard to judge which the best
            material between both of them is. The final decision whether to use
            PVC or WPC completely depends on the preference of the clients and
            the purpose of their use. Moreover, another factor that comes into
            play while selecting between these two alternatives is the pricing.
            <br />
            One must always know that cheap alternatives are available in all
            types of products which lack the quality.So,before buying the
            product it is better to test the quality by yourself in order for a
            last longing product.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            What are the disdvantages of WPC Doors?
          </Accordion.Header>
          <Accordion.Body>
            1)The cost of WPC material is high compared to the other materials
            used for same purposes.
            <br /> 2)WPC material doesn’t have the natural wood texture. <br />
            3) The natural wood feeling is missing especially when furniture and
            doors are prepared.
            <br />
            4) Its resistance to extreme temperature is low compared to wood.
            <br />
            5)WPC Doors doesn't have good screw holding capacity.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default FAQs;
