import React from "react";
import "../Home/Home.css";
import HomeImage from "../../assets/images/home-image.svg";
import Button from "@mui/material/Button";
import Catalogue from "../../assets/media/unipan-catalogue.pdf";

function Home() {
  window.scrollTo(0, 0);
  return (
    <div className="home">
      <section className="home__main">
        <div className="main__heading">
          <h1 className="text-center">
            UNIPAN PROFILES INDIA <br /> PRIVATE LIMITED
          </h1>
          <h5 className="my-4 text-center">
            We are into the business of manufacturing and <br /> marketing of{" "}
            <span> PVC Profiles and Doors </span> since 2002
          </h5>
          <a href={Catalogue} download>
            <Button
              sx={{
                width: "auto",
              }}
              variant="contained"
            >
              Download Our Catalogue
            </Button>
          </a>
        </div>
        <div className="main__image">
          <img src={HomeImage} alt="unipan pvc doors and profiles home svg" />
        </div>
      </section>
    </div>
  );
}

export default Home;
