import React from "react";
import "./Process.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Factory from "../../assets/images/factory.svg";
import Logistics from "../../assets/images/logistics.svg";
import Fitting from "../../assets/images/fitting.jpg";
import { Stack } from "@mui/material";

function Process() {
  return (
    <div className="process">
      <h2 className="text-center mb-4">Unipan Process</h2>
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        height="100%"
        justifyContent={{
          xs: "space-between",
          sm: "space-between",
          md: "space-around",
          xl: "space-evenly",
        }}
        alignItems={{ xs: "center", sm: "center", md: "stretch" }}
      >
        <Card sx={{ maxWidth: 345 }} className="process__card">
          <CardMedia
            component="img"
            image={Factory}
            alt="Unipan Profiles and Doors Manufacturing"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Manufacturing
            </Typography>
            <Typography variant="body2" color="text.secondary">
              We manufacture your PVC panels and doors with the highest quality
              materials and processes, whether you order a single door or 1000
              doors.
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ maxWidth: 345 }} className="process__card">
          <CardMedia
            component="img"
            image={Logistics}
            alt="Unipan Profiles and Doors offers free delivery"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Delivery
            </Typography>
            <Typography variant="body2" color="text.secondary">
              We deliver our products with utmost care at your doorstep without
              any breakage during commute.
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ maxWidth: 345 }} className="process__card">
          <CardMedia
            component="img"
            height="60%"
            image={Fitting}
            alt="Unipan Profiles and Doors are installed by professionals"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Installation
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Our end-to- end service extends beyond delivery to installation
              and maintenance too.
            </Typography>
          </CardContent>
        </Card>
      </Stack>
    </div>
  );
}

export default Process;
