import React from "react";
import "../Features/Features.css";
import WaterProof from "../../assets/images/water-resistant.svg";
import TermiteProof from "../../assets/images/termite-resistant.svg";
import ScratchProof from "../../assets/images/scratch-resistant.svg";
// import Colors from "../../assets/images/colors.svg";
import Warranty from "../../assets/images/warranty.svg";
// import Installation from "../../assets/images/installation.svg";
// import NoPainting from "../../assets/images/no-painting.svg";
import Economical from "../../assets/images/economical.svg";
import FireResistant from "../../assets/images/fire-resistant.svg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
// import { Carousel } from "react-bootstrap";

function Features() {
  return (
    <div className="features">
      <h2 className="features__heading text-center">Why Choose UNIPAN ?</h2>
      <section className="home__features">
        <Card sx={{ maxWidth: 325 }} className="home__feature">
          <CardActionArea>
            <CardMedia
              component="img"
              height="230"
              image={WaterProof}
              alt="Unipan PVC Profiles and Doors are waterproof"
              sx={{ width: "330px" }}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Waterproof
              </Typography>
              <Typography variant="body2" color="text.secondary">
                UNIPAN doors are Waterproof & Weatherproof
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        {/* <Card
          sx={{ maxWidth: 325 }}
          className="home__feature"
        >
          <CardActionArea>
            <CardMedia
              component="img"
              height="230"
              sx={{ width: "200px", marginLeft: "4rem" }}
              image={Installation}
              alt="installation door"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Easy Installation
              </Typography>
              <Typography variant="body2" color="text.secondary">
                UNIPAN doors are easy to install
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card> */}
        <Card sx={{ maxWidth: 325 }} className="home__feature">
          <CardActionArea>
            <CardMedia
              component="img"
              height="230"
              sx={{ width: "240px", marginLeft: "2rem" }}
              image={Warranty}
              alt="Unipan PVC Profiles and Doors offers 5 years of warranty"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Warranty Covered
              </Typography>
              <Typography variant="body2" color="text.secondary">
                UNIPAN offers 5 years warranty
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 325 }} className="home__feature">
          <CardActionArea>
            <CardMedia
              component="img"
              height="230"
              sx={{ width: "240px" }}
              image={TermiteProof}
              alt="Unipan PVC Profiles and Doors are termite proof"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Termite Proof
              </Typography>
              <Typography variant="body2" color="text.secondary">
                UNIPAN doors are resistant to Termites
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        {/* <Card
          sx={{ maxWidth: 325 }}
          className="home__feature"
        >
          <CardActionArea>
            <CardMedia
              component="img"
              height="230"
              sx={{ width: "240px" }}
              image={Colors}
              alt="colorful door"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Many Color Choices
              </Typography>
              <Typography variant="body2" color="text.secondary">
                UNIPAN doors are available in a wide range of colors{" "}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card> */}
        <Card sx={{ maxWidth: 325 }} className="home__feature">
          <CardActionArea>
            <CardMedia
              component="img"
              height="230"
              sx={{ width: "300px" }}
              image={ScratchProof}
              alt="Unipan PVC Profiles and Doors are scratch proof"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                No Scratches!
              </Typography>
              <Typography variant="body2" color="text.secondary">
                UNIPAN doors offer resistance to scratches
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        {/* <Card
          sx={{ maxWidth: 325 }}
          className="home__feature"
        >
          <CardActionArea>
            <CardMedia
              component="img"
              height="230"
              sx={{ width: "200px" }}
              image={NoPainting}
              alt="no painting door"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                No Painting Required
              </Typography>
              <Typography variant="body2" color="text.secondary">
                UNIPAN doors are pre-painted at our factory and do not require
                re-painting for lifetime
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card> */}
        <Card sx={{ maxWidth: 325 }} className="home__feature">
          <CardActionArea>
            <CardMedia
              component="img"
              height="230"
              sx={{ width: "250px" }}
              image={Economical}
              alt="Unipan PVC Profiles and Doors are economical"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Economical Doors
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Unipan Doors are Economical Our moto is: "Save the Trees - Save
                the Planet"
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 325 }} className="home__feature">
          <CardActionArea>
            <CardMedia
              component="img"
              height="230"
              sx={{ width: "320px" }}
              image={FireResistant}
              alt="Unipan PVC Profiles and Doors are fire resistant"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Fire Resistant
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Unipan Doors are Fire Retardant
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </section>
    </div>
  );
}

export default Features;
