import React from "react";
import "../About/About.css";
import AboutLogo from "../../assets/images/about-us-logo.svg";
import OurMission from "../../assets/images/about-mission.svg";
import { Stack } from "@mui/material";
import { Grid } from "@mui/material";

function About() {
  window.scrollTo(0, 0);
  return (
    <div className="about">
      <Stack
        justifyContent={{ md: "space-around", xl: "space-evenly" }}
        alignItems="center"
        direction={{ xs: "column-reverse", sm: "column-reverse", md: "row" }}
      >
        <Grid className="about__text">
          <h2 className="about__heading">ABOUT US</h2>
          <h6>
            We are the first ever company to manufacture highly durable and
            elegant PVC doors in Telangana. Ours is a 20 years old company.
            UNIPAN PVC doors are manufactured with high quality standards to
            give you very long life and trouble free performance.
          </h6>
          <h6>
            We have our own Production Unit where we have latest Machinery.Our
            wide range of products involve Doors, Digital Printed Doors, PVC
            Cabinets, Cupboards, Wall and Ceiling Panels.
          </h6>
        </Grid>
        <img
          src={AboutLogo}
          alt="about us unipan pvc profiles"
          className="about__image"
        />
      </Stack>

      <section className="about__mission">
        <Stack
          justifyContent={{ md: "space-around", xl: "space-evenly" }}
          alignItems="center"
          direction={{
            xs: "column-reverse",
            sm: "column-reverse",
            md: "row",
          }}
        >
          <img
            className="mission__image"
            src={OurMission}
            alt="unipan pvc profiles mission"
          />
          <Stack direction="column" className="about__text">
            <h2 className="about__heading">OUR MISSION</h2>
            <h6>
              UNIPAN PVC (Poly Vinyl Chloride) doors are innovative & highly
              durable wood substitute products that promotes our mission to save
              Earth through usage of green products. Each UNIPAN PVC door you
              buy, saves a tree from axing and preserves your child's
              future.UNIPAN is committed to provide the customers with the best
              solutions in functionality and design.
            </h6>
          </Stack>
        </Stack>
      </section>
    </div>
  );
}

export default About;
