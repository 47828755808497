import React from "react";
import "../CountUp/Counter.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Customer from "../../assets/images/customer.svg";

function Counter() {
  return (
    <div className="counter">
      <div className="counter__section">
        <h3 className="text-center counter__title">Our Happy Customers</h3>
        <section className="counter__count">
          <h1 className="text-center">
            <CountUp start={0} end={45268} redraw={true} duration={2}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
          </h1>
        </section>
      </div>
      <img
        src={Customer}
        alt="svg depicting the number of dealsers and happy customers of unipan pvc profiles and doors."
        className="customer-image"
      />
      <div className="counter__section">
        <h3 className="text-center counter__title">Our Happy Dealers</h3>
        <section className="counter__count">
          <h1 className="text-center">
            <CountUp start={0} end={300} suffix="+" redraw={true} duration={2}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
          </h1>
        </section>
      </div>
    </div>
  );
}

export default Counter;
