import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import "../Header/Header.css";
import UnipanLogo from "../../assets/images/unipan_logo.jpg";

function Header() {
  return (
    <Navbar bg="light" expand="md" sticky="top" className="pt-2 navbar">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src={UnipanLogo}
            width="168px"
            height="50px"
            alt="unipan pvc doors and profiles logo"
            className="mx-4 me-5 navbar__logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              style={{ color: "#595959" }}
              className="me-4 navbar__home"
              as={Link}
              to="/"
            >
              Home
            </Nav.Link>
            <Nav.Link
              style={{ color: "#595959" }}
              className="me-4 navbar__home"
              as={Link}
              to="/products"
            >
              Products
            </Nav.Link>
            <Nav.Link
              style={{ color: "#595959" }}
              className="me-4 navbar__about"
              as={Link}
              to="/about"
            >
              About Us
            </Nav.Link>
            <Nav.Link
              style={{ color: "#595959" }}
              className="me-4 navbar__contact"
              as={Link}
              to="/contact"
            >
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
