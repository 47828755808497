import React from "react";
import ContactImage from "../../assets/images/contact-us.svg";
import { Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dealership from "../../assets/images/dealership.svg";
import "../Contact/Contacts.css";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import PhoneIcon from "@mui/icons-material/Phone";
import BusinessIcon from "@mui/icons-material/Business";
import EmailIcon from "@mui/icons-material/Email";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { createDealer } from "../api/requests/dealers";

function ContactUs() {
  window.scrollTo(0, 0);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [openSuccessAlert, setOpenSuccessAlert] = React.useState(false);
  const [openErrorAlert, setOpenErrorAlert] = React.useState(false);

  const closeSuccessAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessAlert(false);
  };

  const closeErrorAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorAlert(false);
  };

  const [name, setName] = React.useState("");
  const [number, SetNumber] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [about, setAbout] = React.useState("");

  const [nameError, setNameError] = React.useState(false);
  const [numberError, setNumberError] = React.useState(false);
  const [addressError, setAddressError] = React.useState(false);
  const [aboutError, setAboutError] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setNameError(false);
    setNumberError(false);
    setAddressError(false);
    setAboutError(false);

    if (name === "") {
      setNameError(true);
    }
    if (number <= 0 || number === "") {
      setNumberError(true);
    }
    if (address === "") {
      setAddressError(true);
    }
    if (about === "") {
      setAboutError(true);
    }

    if (name && number && address && about) {
      try {
        const dealer = await createDealer({
          name: name,
          contactNumber: number,
          address: address,
          about: about,
        });
        console.log(dealer);
        setOpenSuccessAlert(true);

        setName("");
        SetNumber("");
        setAddress("");
        setAbout("");
      } catch (err) {
        console.log(err);
        setOpenErrorAlert(true);
      }
    } else {
      setOpenErrorAlert(true);
    }
    setName("");
    SetNumber("");
    setAddress("");
    setAbout("");
    console.log(name);
  };

  return (
    <div className="contacts">
      <Stack
        justifyContent={{ md: "space-around", xl: "space-evenly" }}
        alignItems="center"
        direction={{ xs: "column-reverse", sm: "column-reverse", md: "row" }}
      >
        <Stack direction="column" spacing={4}>
          <Box className="contact__text">
            <h2 className="contacts__heading">Looking for Dealership ?</h2>
            <h6>Fill this form and we will get back to you</h6>
          </Box>
          <Box className="contacts__inputs">
            <TextField
              variant="standard"
              required
              fullWidth
              label="Name"
              color="primary"
              margin="dense"
              type="text"
              sx={{ width: "100%", marginBottom: "1rem" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={nameError}
            />
            <TextField
              variant="standard"
              required
              fullWidth
              label="Phone Number"
              color="primary"
              margin="dense"
              type="number"
              sx={{ width: "90%", marginBottom: "1rem" }}
              value={number}
              onChange={(e) => SetNumber(e.target.value)}
              error={numberError}
            />
            <TextField
              variant="standard"
              required
              fullWidth
              label="Address"
              color="primary"
              margin="dense"
              type="text"
              sx={{ width: "90%", marginBottom: "1rem" }}
              className="grid-full"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              error={addressError}
            />
            <TextField
              variant="standard"
              required
              fullWidth
              label="Tell Us About Yourself"
              color="primary"
              margin="dense"
              type="text"
              multiline
              rows={3}
              className="grid-full aboutTexts"
              value={about}
              onChange={(e) => setAbout(e.target.value)}
              error={aboutError}
            />
          </Box>
          <Button
            onClick={handleSubmit}
            variant="contained"
            size="medium"
            sx={{ width: "45%" }}
            className="contacts__button"
          >
            Submit
          </Button>
          <Snackbar
            open={openErrorAlert}
            autoHideDuration={8000}
            onClose={closeErrorAlert}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert
              onClose={closeErrorAlert}
              severity="error"
              sx={{ width: "100%" }}
            >
              Sorry, your response couldn't be submitted
            </Alert>
          </Snackbar>
          <Snackbar
            open={openSuccessAlert}
            autoHideDuration={8000}
            onClose={closeSuccessAlert}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert
              onClose={closeSuccessAlert}
              severity="success"
              sx={{ width: "100%" }}
            >
              Your response has been submitted, we'll get back to you soon
            </Alert>
          </Snackbar>
        </Stack>
        <Box>
          <img
            src={Dealership}
            alt="svg depicting a contract between unipan pvc profiles and their dealers."
            className="dealership"
          />
        </Box>
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        justifyContent={{ md: "space-around", xl: "space-evenly" }}
        alignItems="center"
        sx={{ marginTop: "10%" }}
      >
        <img
          src={ContactImage}
          alt="unipan contact us"
          className="contact__image"
        />
        <Stack direction="column" spacing={2}>
          <h2 className="contacts__heading">Contact Us</h2>
          <div className="contacts__info">
            <PhoneIcon
              sx={{ color: "#407bff", fontSize: "30px", marginRight: "1.2rem" }}
            />
            <h5>
              Phone Number - +91-9849037043 <br />{" "}
              <span className="ml">+91-9849117043</span>
              <br />
              <span className="ml">+040-66745673</span>
              <br />
              <span className="ml">+91-7416512003</span>
            </h5>
          </div>
          <div className="contacts__info">
            <BusinessIcon
              sx={{ color: "#407bff", fontSize: "30px", marginRight: "1.2rem" }}
            />
            <h5>
              1-7-150/1/A, Beside Captain Cook <br /> Hotel Lane, Bakaram,
              Hyderabad-50020
            </h5>
          </div>
          <div className="contacts__info">
            <EmailIcon
              sx={{ color: "#407bff", fontSize: "30px", marginRight: "1.2rem" }}
            />
            <h5>Email - unipanprofiles@gmail.com</h5>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.9648254823173!2d78.49611521423101!3d17.413475606637064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99ed70000001%3A0xae0baa3b3b8b87ce!2sUnipan%20PVC%20Doors%20and%20Profiles!5e0!3m2!1sen!2sin!4v1637035664788!5m2!1sen!2sin"
            width="350"
            height="250"
            style={{ border: "0", marginTop: "2.5rem" }}
            loading="lazy"
            title="unipan address"
            className="map"
            allowFullScreen
          ></iframe>
        </Stack>
      </Stack>
    </div>
  );
}

export default ContactUs;
