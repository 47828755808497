import React from "react";
import "./Clients.css";

function Clients() {
  return (
    <div className="clients">
      <div className="clients__text">
        <h3>Trusted by clients across the country</h3>
      </div>
      <div className="clients__images">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Tata_logo.svg/1116px-Tata_logo.svg.png"
          alt="tata projects uses unipan pvc doors and profiles "
          className="tata__image"
        />
        <img
          src="https://seeklogo.com/images/G/government-of-telangana-logo-4949BC8A09-seeklogo.com.png"
          alt="Telangana Government uses unipan pvc doors and profiles"
          className="clients__ts"
        />
        <img
          src="https://www.vhv.rs/dpng/d/398-3987834_indian-railways-logo-hd-png-download.png"
          alt="Indian Railways uses unipan pvc doors and profiles"
          className="clients__railway"
        />
        <img
          src="https://seeklogo.com/images/A/ap-govt-logo-1AAFDD42D1-seeklogo.com.png"
          alt="Andhra Goverment uses unipan pvc doors and profiles"
          className="clients__ap"
        />
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/ITC_Limited_Logo.svg/1200px-ITC_Limited_Logo.svg.png"
          alt="ITC uses unipan pvc doors and profiles"
          className="clitens__itc"
        />
        <img
          src="https://upload.wikimedia.org/wikipedia/en/6/6c/Yashoda_Hospitals_logo.png"
          alt="Yashoda Hospitals uses unipan pvc doors and celling panels"
          className="clitens__yashoda"
        />
      </div>
    </div>
  );
}

export default Clients;
