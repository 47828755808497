import axios from "axios";

let performRequest = (path, method, body) => {
  let pathUrl = "https://backend.unipanprofiles.com";
  path = pathUrl + path;
  return new Promise((resolve, reject) => {
    const headers = {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    // eslint-disable-next-line
    axios({ method: method, url: path, data: body, headers: headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default performRequest;
