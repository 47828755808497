import React from "react";
import { Link } from "react-router-dom";
import "../Interiors/Interior.css";
import { Button, Stack } from "@mui/material";

function Interior() {
  return (
    <div className="interiors">
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        justifyContent={{
          xs: "space-between",
          sm: "space-between",
          md: "space-evenly",
          xl: "space-evenly",
        }}
        alignItems="center"
      >
        <div className="interior__image">
          <img
            src="https://5.imimg.com/data5/ST/RN/MY-7399136/multi-color-pvc-wall-panels-500x500.jpg"
            alt="unipan offers the best solution for your PVC interiors"
          />
        </div>
        <div className="interior__text">
          <h3>
            Yes! PVC Interiors <br /> Do Look Good
          </h3>
          <h5>Budget Friendly Option for your Interiors</h5>
          <h6>
            Unipan offers various interior options likes Cupboards, TV Cabinets,
            Wall & Celling Panels, PVC Flooring, PVC Marble Sheets, PVC Louvers
            and many more.
          </h6>
          <Link to="/interiors">
            <Button
              sx={{
                width: "30%",
              }}
              variant="contained"
            >
              View More
            </Button>
          </Link>
        </div>
      </Stack>
    </div>
  );
}

export default Interior;
