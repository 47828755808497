import React from "react";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./Panels.css";
import CWSTV from "../../assets/images/LP-D1-CW.jpg";
import EVSTV from "../../assets/images/LP-D1-EV.jpg";
import CWD1 from "../../assets/images/cw-d1.jpg";
import EVD2 from "../../assets/images/ev-d2.jpg";
import RWL from "../../assets/images/rw-l.jpg";
import EvitaD2 from "../../assets/images/LP-D2-EV.jpg";
import CWD2 from "../../assets/images/LP-D2-CW.jpg";
import NGD2 from "../../assets/images/LP-D2-NG.jpg";
import RWD2 from "../../assets/images/LP-D2-RW.jpg";
import RoseWoodL from "../../assets/images/LP-L-RW.jpg";
import CWL from "../../assets/images/LP-L-CW.jpg";
import NGL from "../../assets/images/LP-L-NG.jpg";
import EVL from "../../assets/images/LP-L-EV.jpg";
import RW3 from "../../assets/images/HP-D3-RW.png";
import CW4 from "../../assets/images/HP-D4-CW.png";
import EV5 from "../../assets/images/HP-D5-EV.png";
// import MP11 from "../../assets/images/MP11.jpg";
import RWH3 from "../../assets/images/rw-h3.jpg";
import EV4 from "../../assets/images/ev-4.jpg";
import WN6 from "../../assets/images/wn-6.png";
import CW8 from "../../assets/images/cw-8.jpg";
import EV3 from "../../assets/images/ev-3.png";
import CW3 from "../../assets/images/cw-3.png";
import EV4H from "../../assets/images/ev-4.png";
import CW4H from "../../assets/images/cw-4.png";
import RW4H from "../../assets/images/rw-4.png";
import WN6H from "../../assets/images/wn-6h.png";
import CW6H from "../../assets/images/cw-6h.png";
import CW8H from "../../assets/images/cw-8h.png";
import RW8H from "../../assets/images/rw-8h.png";
import EV8H from "../../assets/images/ev-8h.png";
import LightPanelOutline from "../../assets/images/light-panel-outline.png";
import HeavyPanelOutline from "../../assets/images/heavy-panel-outline.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ color: "white" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapDialog2 = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle2 = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ color: "white" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle2.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function Panels() {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const [designOne, setDesignOne] = React.useState(true);
  const [designTwo, setDesignTwo] = React.useState(false);
  const [designThree, setDesignThree] = React.useState(false);
  // eslint-disable-next-line
  const [designFour, setDesignFour] = React.useState(false);

  const openDesignOne = () => {
    setDesignOne(true);
    setDesignTwo(false);
    setDesignThree(false);
    setDesignFour(false);
  };

  const openDesignTwo = () => {
    setDesignTwo(true);
    setDesignOne(false);
    setDesignThree(false);
    setDesignFour(false);
  };

  const openDesignThree = () => {
    setDesignThree(true);
    setDesignTwo(false);
    setDesignOne(false);
    setDesignFour(false);
  };

  const openDesignFour = () => {
    setDesignFour(true);
    setDesignThree(false);
    setDesignTwo(false);
    setDesignOne(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  return (
    <div className="panels">
      <Stack
        direction={{ xs: "column-reverse", sm: "column-reverse", md: "row" }}
      >
        <Carousel
          width="350px"
          showThumbs={true}
          showStatus={false}
          className="panel__slider"
        >
          <div className="panels__image">
            <img src={RoseWoodL} alt="panel" />
          </div>
          <div className="panels__image">
            <img src={CWSTV} alt="panel" />
          </div>
          <div className="panels__image">
            <img src={EVSTV} alt="panel" />
          </div>
        </Carousel>
        <div className="panel__text">
          <Stack
            direction="column"
            justifyContent="space-evenly"
            height="60%"
            style={{ marginLeft: "15%", marginTop: "2rem" }}
          >
            <h4 className="panel__subheading" style={{ marginTop: "-1rem" }}>
              Light Panels
            </h4>
            <p>• Thickness - 20mm, Guage - 1mm.</p>
            <p>• Available widths - 19mm, 21.5mm, 25mm.</p>
            <p>• Customized Sizes also available</p>
            <p>• Termite and borer proof</p>
            <p>• Waterproof and weatherproof</p>
            <p>
              • Strengthened with polymeric reinforcement to avoid weathering
            </p>
            <p>• Maintenance-free</p>
            <Button
              variant="contained"
              sx={{ width: "30%" }}
              className="panel__button"
              onClick={handleClickOpen}
            >
              Specifications
            </Button>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
                sx={{
                  color: "white",
                  background: "#005792",
                  fontWeight: "600",
                }}
              >
                Panel Specifications
              </BootstrapDialogTitle>
              <DialogContent dividers>
                <div className="specifications__box">
                  <img
                    src={LightPanelOutline}
                    className="specifications__image"
                    alt="lp-ol"
                  />
                  <Typography gutterBottom>
                    Light Panels of UNIPAN are made of rigid PVC extruded hollow
                    profile of size 20mm X 620mm with a wall thickness of 1mm
                    (with usual process variation of +/- 0.1mm).
                  </Typography>
                </div>
              </DialogContent>
            </BootstrapDialog>
          </Stack>
        </div>
      </Stack>

      <Stack
        style={{ marginLeft: "3rem", marginTop: "2rem", marginBottom: "3rem" }}
        direction={{ xs: "column", sm: "column", md: "row" }}
      >
        <div className="panel__text">
          <Stack direction="column" justifyContent="space-around" height="60%">
            <h4 className="panel__subheading">
              Select the Design of Your Choice
            </h4>
            <p>
              UNIPAN offers PVC PROFILES in various colours & textures to
              enhance the aesthetics of your home interiors. In Light Panels we
              have 3 designs. Tap on the designs to view them
            </p>
            <Stack direction="row" className="panel__designs lightPanelDesigns">
              <Stack direction="column" alignItems="center">
                <img src={CWD1} alt="design1" onClick={openDesignOne} />
                <h6 style={{ fontWeight: "600", fontSize: "0.9rem" }}>
                  Design-1
                </h6>
              </Stack>
              <Stack direction="column" alignItems="center">
                <img src={EVD2} alt="design2" onClick={openDesignTwo} />
                <h6 style={{ fontWeight: "600", fontSize: "0.9rem" }}>
                  Design-2
                </h6>
              </Stack>
              <Stack direction="column" alignItems="center">
                <img src={RWL} alt="design3" onClick={openDesignThree} />
                <h6 style={{ fontWeight: "600", fontSize: "0.9rem" }}>
                  Design-L
                </h6>
              </Stack>
              {/* <Stack direction="column" alignItems="center">
                <img src={MP11} alt="design4" onClick={openDesignThree} />
                <h6 style={{ fontWeight: "600", fontSize: "0.9rem" }}>
                  Design-11
                </h6>
              </Stack> */}
            </Stack>
          </Stack>
        </div>
        {designOne ? (
          <Carousel
            width="350px"
            showThumbs={true}
            showStatus={false}
            className="panel__selector"
          >
            <div className="panels__image">
              <img src={CWSTV} alt="panel" />
            </div>
            <div className="panels__image">
              <img src={EVSTV} alt="panel" />
            </div>
          </Carousel>
        ) : designTwo ? (
          <Carousel
            width="350px"
            showThumbs={true}
            showStatus={false}
            className="panel__selector"
          >
            <div className="panels__image">
              <img src={EvitaD2} alt="panel" />
            </div>
            <div className="panels__image">
              <img src={CWD2} alt="panel" />
            </div>
            <div className="panels__image">
              <img src={NGD2} alt="panel" />
            </div>
            <div className="panels__image">
              <img src={RWD2} alt="panel" />
            </div>
          </Carousel>
        ) : (
          <Carousel
            width="350px"
            showThumbs={true}
            showStatus={false}
            className="panel__selector"
          >
            <div className="panels__image">
              <img src={RoseWoodL} alt="panel" />
            </div>
            <div className="panels__image">
              <img src={CWL} alt="panel" />
            </div>
            <div className="panels__image">
              <img src={NGL} alt="panel" />
            </div>
            <div className="panels__image">
              <img src={EVL} alt="panel" />
            </div>
          </Carousel>
        )}
      </Stack>

      <Stack
        direction={{ xs: "column-reverse", sm: "column-reverse", md: "row" }}
      >
        <Carousel width="350px" showThumbs={true} showStatus={false}>
          <div className="panels__image">
            <img src={RW3} alt="panel" />
          </div>
          <div className="panels__image">
            <img src={CW4} alt="panel" />
          </div>
          <div className="panels__image">
            <img src={EV5} alt="panel" />
          </div>
        </Carousel>
        <div className="panel__text">
          <Stack
            direction="column"
            justifyContent="space-evenly"
            height="60%"
            style={{ marginLeft: "15%", marginTop: "2rem" }}
          >
            <h4 className="panel__subheading" style={{ marginTop: "-1rem" }}>
              Heavy Panels
            </h4>
            <p>• Thickness - 28mm, Guage - 1.5mm.</p>
            <p>• Available widths - 26mm, 27mm, 28mm, 30mm.</p>
            <p>• Customized Sizes also available</p>
            <p>• Termite and borer proof</p>
            <p>• Waterproof and weatherproof</p>
            <p>
              • Strengthened with polymeric reinforcement to avoid weathering
            </p>
            <p>• Maintenance-free</p>
            <Button
              variant="contained"
              sx={{ width: "30%" }}
              className="panel__button"
              onClick={handleClickOpen2}
            >
              Specifications
            </Button>
            <BootstrapDialog2
              onClose={handleClose2}
              aria-labelledby="customized-dialog-title"
              open={open2}
            >
              <BootstrapDialogTitle2
                id="customized-dialog-title"
                onClose={handleClose2}
                sx={{
                  color: "white",
                  background: "#005792",
                  fontWeight: "600",
                }}
              >
                Panel Specifications
              </BootstrapDialogTitle2>
              <DialogContent dividers>
                <div className="specifications__box">
                  <img
                    src={HeavyPanelOutline}
                    className="specifications__image"
                    alt="lp-ol"
                  />
                  <Typography gutterBottom>
                    Heavy Panels of UNIPAN are made of rigid PVC extruded hollow
                    profile of size 20mm X 620mm with a wall thickness of 1mm
                    (with usual process variation of +/- 0.1mm).
                  </Typography>
                </div>
              </DialogContent>
            </BootstrapDialog2>
          </Stack>
        </div>
      </Stack>

      <Stack
        style={{ marginLeft: "3rem", marginTop: "2rem", marginBottom: "3rem" }}
        direction={{ xs: "column", sm: "column", md: "row" }}
      >
        <div className="panel__text">
          <Stack direction="column" justifyContent="space-around" height="60%">
            <h4 className="panel__subheading">
              Select the Design of Your Choice
            </h4>
            <p>
              UNIPAN offers PVC PROFILES in various colours & textures to
              enhance the aesthetics of your home interiors. In Heavy Panels we
              have 4 designs. Tap on the designs to view them
            </p>
            <Stack direction="row" className="panel__designs">
              <Stack direction="column" alignItems="center">
                <img src={RWH3} alt="design1" onClick={openDesignOne} />
                <h6 style={{ fontWeight: "600", fontSize: "0.9rem" }}>
                  Design-3
                </h6>
              </Stack>
              <Stack direction="column" alignItems="center">
                <img src={EV4} alt="design2" onClick={openDesignTwo} />
                <h6 style={{ fontWeight: "600", fontSize: "0.9rem" }}>
                  Design-4
                </h6>
              </Stack>
              <Stack direction="column" alignItems="center">
                <img src={WN6} alt="design3" onClick={openDesignThree} />
                <h6 style={{ fontWeight: "600", fontSize: "0.9rem" }}>
                  Design-6
                </h6>
              </Stack>
              <Stack direction="column" alignItems="center">
                <img src={CW8} alt="design4" onClick={openDesignFour} />
                <h6 style={{ fontWeight: "600", fontSize: "0.9rem" }}>
                  Design-8
                </h6>
              </Stack>
            </Stack>
          </Stack>
        </div>
        {designOne ? (
          <Carousel
            width="350px"
            showThumbs={true}
            showStatus={false}
            className="panel__selector"
          >
            <div className="panels__image">
              <img src={RW3} alt="panel" />
            </div>
            <div className="panels__image">
              <img src={EV3} alt="panel" />
            </div>
            <div className="panels__image">
              <img src={CW3} alt="panel" />
            </div>
          </Carousel>
        ) : designTwo ? (
          <Carousel
            width="350px"
            showThumbs={true}
            showStatus={false}
            className="panel__selector"
          >
            <div className="panels__image">
              <img src={EV4H} alt="panel" />
            </div>
            <div className="panels__image">
              <img src={CW4H} alt="panel" />
            </div>
            <div className="panels__image">
              <img src={RW4H} alt="panel" />
            </div>
          </Carousel>
        ) : designThree ? (
          <Carousel
            width="350px"
            showThumbs={true}
            showStatus={false}
            className="panel__selector"
          >
            <div className="panels__image">
              <img src={WN6H} alt="panel" />
            </div>
            <div className="panels__image">
              <img src={CW6H} alt="panel" />
            </div>
          </Carousel>
        ) : (
          <Carousel
            width="350px"
            showThumbs={true}
            showStatus={false}
            className="panel__selector"
          >
            <div className="panels__image">
              <img src={CW8H} alt="panel" />
            </div>
            <div className="panels__image">
              <img src={EV8H} alt="panel" />
            </div>
            <div className="panels__image">
              <img src={RW8H} alt="panel" />
            </div>
          </Carousel>
        )}
      </Stack>
    </div>
  );
}

export default Panels;
