import React from "react";
import ConstructionImage from "../../assets/images/under-construction.svg";
import "./Doors.css";

function Doors() {
  window.scrollTo(0, 0);
  return (
    <div className="doors">
      <img src={ConstructionImage} alt="page under construction" />
      <h3>We are clicking some nice photos and it's worth the wait.</h3>
    </div>
  );
}

export default Doors;
