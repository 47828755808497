import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import { Route, Switch } from "react-router-dom";
import ContactUs from "./components/Contact/ContactUs";
import About from "./components/About/About";
import Features from "./components/Features/Features";
import CountUp from "./components/CountUp/Counter";
import Products from "./components/Products/Products";
import Panels from "./components/Products/Panels";
import Process from "./components/Process/Process";
import Colours from "./components/Colours/Colours";
import Clients from "./components/Clients/Clients";
import MakeInIndia from "./components/MakeInIndia/MakeInIndia";
import FAQs from "./components/FAQs/FAQs";
import Footer from "./components/Footer/Footer";
import Interior from "./components/Interiors/Interior";
import Doors from "./components/Products/Doors";
import Interiors from "./components/Products/Interiors";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact>
          <Header />
          <Home />
          <Features />
          <CountUp />
          <Interior />
          <Process />
          <Colours />
          <Clients />
          <MakeInIndia />
          <FAQs />
          <Footer />
        </Route>
        <Route path="/contact">
          <Header />
          <ContactUs />
          <Footer />
        </Route>
        <Route path="/about">
          <Header />
          <About />
          <Footer />
        </Route>
        <Route path="/products">
          <Header />
          <Products />
          <Footer />
        </Route>
        <Route path="/panels">
          <Header />
          <Panels />
          <Footer />
        </Route>
        <Route to="/doors">
          <Header />
          <Doors />
        </Route>
        <Route path="/interiors">
          <Header />
          <Interiors />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
