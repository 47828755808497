import { Stack } from "@mui/material";
import React from "react";
import "./MakeInIndia.css";

function MakeInIndia() {
  return (
    <div className="makeInIndia">
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        justifyContent={{
          xs: "space-between",
          sm: "space-between",
          md: "space-evenly",
          xl: "space-evenly",
        }}
        alignItems="center"
      >
        <div className="makeInIndia__image">
          <img
            src="https://upload.wikimedia.org/wikipedia/en/4/46/Make_In_India.png"
            alt="Unipan Profiles and Doors are manufactured with Make In India Concept."
          />
        </div>
        <div className="makeInIndia__text">
          <h6>
            We strongly believe in the concept of{" "}
            <span style={{ color: "#005792", fontWeight: "600" }}>
              Make In India.
            </span>{" "}
            All our products are manufactured in India with the latest
            technology and machinery.
          </h6>
        </div>
      </Stack>
    </div>
  );
}

export default MakeInIndia;
