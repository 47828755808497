import React, { useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import Button from "@mui/material/Button";
import { sendEmail } from "../api/requests/emails";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function Footer() {
  const [email, setEmail] = useState("");

  // Alert
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [openSuccessAlert, setOpenSuccessAlert] = React.useState(false);
  const [openErrorAlert, setOpenErrorAlert] = React.useState(false);

  const closeSuccessAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessAlert(false);
  };

  const closeErrorAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorAlert(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.length > 0) {
      try {
        const emailId = await sendEmail({ email });
        console.log(emailId);
        setEmail("");
        setOpenSuccessAlert(true);
      } catch (err) {
        console.log(err);
      }
    } else {
      setOpenErrorAlert(true);
    }
  };

  return (
    <div className="footer">
      <div className="footer__main">
        <h4 className="footer__heading mainHeading">
          UNIPAN PROFILES INDIA PVT LTD
          <br /> "save the trees, save the planet"{" "}
        </h4>
        <div className="footer__icons">
          <InstagramIcon style={{ color: "white", fontSize: "30px" }} />
          <p>
            <a
              href="https://www.instagram.com/unipanprofiles/?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
          </p>
          <FacebookIcon style={{ color: "white", fontSize: "30px" }} />
          <p>
            <a
              href="https://www.facebook.com/unipanprofiles/"
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>
          </p>
        </div>
      </div>
      <div className="footer__products">
        <h6 className="footer__heading useful__heading">Products</h6>
        <p>
          <Link to="/doors">PVC Doors</Link>
        </p>
        <p>
          <Link to="/panels">Panels</Link>
        </p>
        <p>
          <Link to="/interiors">Interiors</Link>
        </p>
      </div>
      <div className="footer__useful">
        <h6 className="useful__heading">Useful Links</h6>
        <p>
          <Link to="/contact">Contact Us</Link>
        </p>
        <p>
          <Link to="/about">About Us</Link>
        </p>
        <p>
          <Link to="/products">Products</Link>
        </p>
      </div>
      <div>
        <input
          type="email"
          placeholder="Email Address"
          className="footer__input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          className="footer__button"
          variant="contained"
          size="small"
          sx={{ marginLeft: "1rem" }}
          onClick={handleSubmit}
          type="email"
        >
          Submit
        </Button>
      </div>
      <Snackbar
        open={openErrorAlert}
        autoHideDuration={8000}
        onClose={closeErrorAlert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={closeErrorAlert}
          severity="error"
          sx={{ width: "100%" }}
        >
          Sorry, your response couldn't be submitted
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccessAlert}
        autoHideDuration={8000}
        onClose={closeSuccessAlert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={closeSuccessAlert}
          severity="success"
          sx={{ width: "100%" }}
        >
          Your response has been submitted, we'll get back to you soon
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Footer;
