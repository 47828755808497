import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "./Colours.css";

function Colours() {
  window.scrollTo(0, 0);
  return (
    <div className="colors">
      <h2 className="text-center mb-5">Available Colors</h2>
      <div className="colors__main">
        <div>
          <div
            className="colors__circle"
            style={{ backgroundColor: "#e78c32" }}
          ></div>
          <h6 className="colors__text colors__maple">Maple</h6>
        </div>

        <div>
          <div
            className="colors__circle"
            style={{ backgroundColor: "#d05f1e" }}
          ></div>
          <h6 className="colors__text">Cherry Wood</h6>
        </div>
        <div>
          <div
            className="colors__circle"
            style={{ backgroundColor: "#8e3f32" }}
          ></div>
          <h6 className="colors__text">Rose Wood</h6>
        </div>
        <div>
          <div
            className="colors__circle"
            style={{ backgroundColor: "#e1ebeb" }}
          ></div>
          <h6 className="colors__text colors__evita">Evita</h6>
        </div>
        <div>
          <div
            className="colors__circle"
            style={{ backgroundColor: "#262a2b" }}
          ></div>
          <h6 className="colors__text colors__black">Black</h6>
        </div>
        <div>
          <div
            className="colors__circle"
            style={{ backgroundColor: "#7b4b38" }}
          ></div>
          <h6 className="colors__text">Wall nut</h6>
        </div>
      </div>
      <div className="colors__button">
        <Button
          component={Link}
          to="/products"
          sx={{ width: "auto" }}
          variant="contained"
        >
          View More
        </Button>
      </div>
    </div>
  );
}

export default Colours;
