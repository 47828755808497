import React from "react";
import "../Products/Products.css";
import { Stack } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import PanelsImage from "../../assets/images/panels_image.jpg";
import PanelsImage2 from "../../assets/images/panels_image2.jpg";
import Interiors1 from "../../assets/images/interiors1.jpeg";

function Products() {
  window.scrollTo(0, 0);

  return (
    <div className="products">
      <Stack
        justifyContent={{ md: "space-around", xl: "space-evenly" }}
        alignItems="center"
        direction={{ xs: "column", sm: "column", md: "row" }}
        className="products__text"
      >
        <Stack direction="column" className="product__text">
          <h3 className="products__heading">Panels, Sections & Frames</h3>
          <h6 className="products__description">
            UNIPAN PVC Panels, Sections and Frames are manufactured with
            extrusion technique with the use of highest quality materials. We
            make PVC Panels in a variety of measurements. In recent years PVC
            has grown enormously in popularity and is now one of the most
            recommended and most widely chosen material by companies for
            enclosure production.
          </h6>
          <Link to="/panels">
            <Button
              variant="contained"
              sx={{
                width: "auto",
                backgroundColor: "#2196f3",
                fontWeight: "500",
                marginTop: "1rem",
              }}
              className="products__button"
            >
              Read More
            </Button>
          </Link>
        </Stack>

        {window.screen.width < 880 ? (
          <Carousel
            width="280px"
            autoPlay
            infiniteLoop
            interval={3000}
            showThumbs={false}
            className="products__slider"
          >
            <div>
              <img src={PanelsImage2} alt="unipan pvc panel/profiles/sheets" />
            </div>
            <div>
              <img src={PanelsImage} alt="unipan pvc panel/profiles/sheets" />
            </div>
          </Carousel>
        ) : (
          <Carousel
            width="380px"
            autoPlay
            infiniteLoop
            interval={3000}
            showThumbs={false}
          >
            <div>
              <img src={PanelsImage2} alt="unipan pvc panel/profiles/sheets" />
            </div>
            <div>
              <img src={PanelsImage} alt="unipan pvc panel/profiles/sheets" />
            </div>
          </Carousel>
        )}
      </Stack>
      <section className="products__doors">
        <Stack
          justifyContent={{ md: "space-around", xl: "space-evenly" }}
          alignItems="center"
          direction={{ xs: "column", sm: "column", md: "row-reverse" }}
          className="products__text"
        >
          <Stack direction="column" className="product__text">
            <h3 className="products__heading">PVC Doors</h3>
            <h6 className="products__description">
              UNIPAN PVC Doors sets difference in the way of home decor and
              furnishing. They are easy to maintain, durable and damage
              resistant. They come in a variety of designs and colours so that
              there is plenty of choice for a beautiful home, without having to
              compromise on quality.
            </h6>
            <Link to="/doors">
              <Button
                variant="contained"
                sx={{
                  width: "auto",
                  backgroundColor: "#2196f3",
                  fontWeight: "500",
                  marginTop: "1rem",
                }}
                className="products__button"
              >
                Read More
              </Button>
            </Link>
          </Stack>
          {window.screen.width < 880 ? (
            <Carousel
              width="280px"
              autoPlay
              infiniteLoop
              interval={3000}
              showThumbs={false}
              className="products__slider"
            >
              <div>
                <img
                  src="https://5.imimg.com/data5/XD/XD/WO/SELLER-60491569/pvc-bathroom-door-500x500.jpg"
                  alt="unipan pvc"
                />
              </div>
            </Carousel>
          ) : (
            <Carousel
              width="350px"
              autoPlay
              infiniteLoop
              interval={3000}
              showThumbs={false}
            >
              <div>
                <img
                  src="https://5.imimg.com/data5/XD/XD/WO/SELLER-60491569/pvc-bathroom-door-500x500.jpg"
                  alt="unipan pvc"
                />
              </div>
            </Carousel>
          )}
        </Stack>
      </section>

      <section className="products__cupboards">
        <Stack
          justifyContent={{ md: "space-around", xl: "space-evenly" }}
          alignItems="center"
          direction={{ xs: "column", sm: "column", md: "row" }}
          className="products__text"
        >
          <Stack direction="column" className="product__text">
            <h3 className="products__heading">PVC Interiors</h3>
            <h6 className="products__description">
              PVC is replacing traditional interior materials such as Plywood,
              WPC, Laminates, Decolam etc. It's versatility, cost effectiveness
              and excellent usage makes it the most important product in the
              interiors section. UNIPAN provides the best solution in making
              your house look beautiful.
            </h6>
            <Link to="/interiors">
              <Button
                variant="contained"
                sx={{
                  width: "auto",
                  backgroundColor: "#2196f3",
                  fontWeight: "500",
                  marginTop: "1rem",
                }}
                className="products__button"
              >
                Read More
              </Button>
            </Link>
          </Stack>
          {window.screen.width < 880 ? (
            <Carousel
              width="280px"
              autoPlay
              infiniteLoop
              interval={2000}
              showThumbs={false}
              className="products__slider"
            >
              <div>
                <img src={Interiors1} alt="unipan interiors" />
              </div>
            </Carousel>
          ) : (
            <Carousel
              width="380px"
              autoPlay
              infiniteLoop
              interval={2000}
              showThumbs={false}
            >
              <div>
                <img src={Interiors1} alt="unipan interiors" />
              </div>
            </Carousel>
          )}
        </Stack>
      </section>
    </div>
  );
}

export default Products;
